import gql from "graphql-tag";

const VALIDATE_ROOM_TOKEN = gql`
query validateRoomToken($entityId: Int!,$token:String! $module: String!) {
    validateRoomToken(token: $token,entityId: $entityId, module:$module){
      waitingToken
      entityId
      jwtToken
    }
  }
`;

export default VALIDATE_ROOM_TOKEN;