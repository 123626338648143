import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import * as Styled from "./styled";

const Option = ({ step, text, image }) => (
  <Styled.Wrapper>
    <Styled.Step>{step}</Styled.Step>
    <Styled.StepText>
      {parse(text)}
      {image && <Styled.StepImage src={image} width={246} />}
    </Styled.StepText>
  </Styled.Wrapper>
);

Option.propTypes = {
  step: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.any,
};

export default Option;
