import React from "react";
import PropTypes from "prop-types";

import * as Styled from "./styled";

const Spinner = ({ size, color, total, speedMultiplier }) => (
  <Styled.Container>
    {Array.from(Array(total).keys()).map((index) => (
      <Styled.Spinner
        key={index}
        index={index}
        size={Styled.sizes[size]?.size}
        margin={Styled.sizes[size]?.margin}
        speedMultiplier={speedMultiplier}
        color={color}
      />
    ))}
  </Styled.Container>
);

Spinner.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  color: PropTypes.string,
  speedMultiplier: PropTypes.number,
  total: PropTypes.number,
};

Spinner.defaultProps = {
  size: "md",
  color: "#03ABE6",
  speedMultiplier: 1,
  total: 3,
};

export default Spinner;
