import { ApolloClient, InMemoryCache } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { SubscriptionClient } from "subscriptions-transport-ws";

import LocalStorageService from "./LocalStorageService";
import enviroment from "../utils/helpers/enviroment";

const wsClient = new SubscriptionClient(
 enviroment.GRAPHQL_WEBSOCKET_ENDPOINT,
  {
    reconnect: true,
    minTimeout: 30000,
  }
);

const httpLink = new HttpLink({
  uri: enviroment.GRAPHQL_ENDPOINT,
});

const wsLink = new WebSocketLink(wsClient);

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${LocalStorageService.getUser().token}`,
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const ApolloService = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

export default ApolloService;
