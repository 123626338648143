import React from "react";

import "./styles.css";

const Spinner = () => (
  <div className="la-line-spin-fade-rotating">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Spinner;
