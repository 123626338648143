import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px 0px -20px;
`;

export const Step = styled.div.attrs((props) => ({
  align: props.align || "start",
  active: props.active || false,
}))`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => `flex-${props.align}`};
  margin: 0px 15px;
  cursor: pointer;
  color: ${(props) => props.active ? '#FF881F': '#a8a8a8'};
`;

export const StepCircle = styled.div.attrs((props) => ({
  active: props.active || false,
}))`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.active ? '#FF881F': '#A9A9A7'};
  margin-bottom: 5px;
  background: ${(props) => props.active ? '#FF881F': 'transparent'};
`;
