import React from "react";
import PropTypes from "prop-types";
import { AnimatePresence } from "framer-motion";
import { lighten } from "polished";

import { springSlide } from "../../utils/animations";
import Spinner from "../Spinner";

import * as Styled from "./styled";

const NewButton = ({
  size,
  color,
  background,
  children,
  disabled,
  fullWidth,
  variant,
  loading,
  onClick,
  maxWidth,
  ...rest
}) => {
  const [isHover, handleHover] = React.useState(false);

  const buttonVariants = {
    outlined: {
      color: background,
      border: `1px solid ${disabled ? "#707070" : background}`,
      background: "rgba(0,0,0,0)",
      width: fullWidth ? "100%" : "auto",
    },
    outlinedHover: {
      color,
      background: lighten(0.1, background),
      border: `1px solid ${lighten(0.1, background)}`,
      width: fullWidth ? "100%" : "auto",
    },
    outlinedDisabled: {
      color: "#707070",
      border: `1px solid ${disabled ? "#707070" : background}`,
      background: "rgba(0,0,0,0)",
      width: fullWidth ? "100%" : "auto",
    },
    link: {
      color: background,
      border: "1px solid rgba(255, 255, 255, .1)",
      background: "rgba(0,0,0,0)",
      width: fullWidth ? "100%" : "auto",
    },
    linkDisabled: {
      color: "#707070",
      border: "1px solid rgba(255, 255, 255, .1)",
      background: "rgba(0,0,0,0)",
      width: fullWidth ? "100%" : "auto",
    },
    normal: {
      color,
      background,
      border: `1px solid ${background}`,
      width: fullWidth ? "100%" : "auto",
    },
    normalHover: {
      color,
      background: lighten(0.1, background),
      border: `1px solid ${lighten(0.1, background)}`,
      width: fullWidth ? "100%" : "auto",
    },
    disabled: {
      color: "#707070",
      background: "#E3E3E3",
      border: "1px solid #E3E3E3",
      width: fullWidth ? "100%" : "auto",
    },
  };

  const handleClick = () => {
    if (!loading) {
      onClick && onClick();
    }
  };

  const getAnimation = () => {
    switch (variant) {
      case "outlined":
        if (disabled) {
          return "outlinedDisabled";
        }

        if (isHover) {
          return "outlinedHover";
        }

        return "outlined";
      case "link":
        if (disabled) {
          return "linkDisabled";
        }

        return "link";
      default:
        if (disabled) {
          return "disabled";
        }

        if (isHover) {
          return "normalHover";
        }

        return "normal";
    }
  };

  return (
    <AnimatePresence>
      <Styled.Button
        size={size}
        hasLabel={Boolean(children)}
        color={color}
        background={background}
        fullWidth={fullWidth}
        disabled={disabled}
        variant={variant}
        loading={loading.toString()}
        onClick={handleClick}
        maxWidth={maxWidth}
        onHoverStart={() => handleHover(true)}
        onHoverEnd={() => handleHover(false)}
        {...rest}
        variants={buttonVariants}
        initial={getAnimation()}
        animate={getAnimation()}
        transition={springSlide}
      >
        {loading ? (
          <Spinner
            color={["outlined", "link"].includes(variant) ? background : color}
            size="xs"
          />
        ) : (
          <>{children}</>
        )}
      </Styled.Button>
    </AnimatePresence>
  );
};

NewButton.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.number,
  rounded: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(["contained", "outlined", "link"]),
  paddingVertical: PropTypes.number,
};

NewButton.defaultProps = {
  color: "#ffffff",
  background: "#03ABE6",
  onClick: null,
  size: "md",
  disabled: false,
  fullWidth: false,
  rounded: false,
  loading: false,
  maxWidth: null,
  variant: "contained",
  paddingVertical: 8,
};

export default NewButton;
