import styled from "styled-components";
import { isMobile } from "react-device-detect";

export const Container = styled.div`
  width: ${isMobile ? 340 : 474}px;
  margin: 35px 17px;
  display: flex;
  justify-content: center;
`;

export const Card = styled.div`
  border-radius: 10px;
  background-color: #fefefe;
  box-shadow: 0px 0px 15px #0000000d;
  width: 100%;
  min-height: 479px;
`;

export const Content = styled.div`
  padding: ${isMobile ? 30 : 50}px 15px 24px;
  color: #707070;
  display: flex;
  justify-content: center;
  flex-direction: column;
  white-space: pre-line;
`;

export const Title = styled.div`
  font-size: ${isMobile ? 16 : 18}px;
  color: #444444;
  margin-bottom: 10px;
  text-align: center;
  line-height: 22px;

  > span {
    color: #ff881f;
  }
`;

export const Legend = styled.div`
  text-align: center;
  font-size: ${isMobile ? 12 : 14}px;
  line-height: 18px;
`;
