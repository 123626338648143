import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { isMobile } from "react-device-detect";

import { STEP } from "../../../../utils/types";

import Header from "../Header";
import Logo from "../Logo";

import * as Styled from "./styled";

const Card = ({ header, title, legend, step }) => (
  <Styled.Container>
    <Styled.Card>
      <Header
        height={
          [STEP.FALLBACK, STEP.FALLBACK_TWO].includes(step) && isMobile
            ? 350
            : null
        }
        align={
          [STEP.FALLBACK, STEP.FALLBACK_TWO].includes(step) && isMobile
            ? "flex-start"
            : null
        }
      >
        {header}
      </Header>
      <Logo />
      <Styled.Content>
        <Styled.Title>{parse(title)}</Styled.Title>
        {legend && <Styled.Legend>{parse(legend)}</Styled.Legend>}
      </Styled.Content>
    </Styled.Card>
  </Styled.Container>
);

Card.propTypes = {
  header: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  legend: PropTypes.string,
  step: PropTypes.string.isRequired,
};

export default Card;
