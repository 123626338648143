import React from "react";

import errors from "../services/errors";

const Error = ({ error }) => {
  const internalError = React.useMemo(() => {
    if (errors.hasOwnProperty(error)) {
      return errors[error];
    }

    return {
      title: error,
    };
  }, [error]);

  return (
    <div className="contentContainer">
      <div className="warningContainer">
        <pre className="mainWarning">{internalError?.title}</pre>
        {internalError?.description !== undefined ? (
          <pre className="secondWarning">{internalError?.description}</pre>
        ) : null}
      </div>
    </div>
  );
};

export default Error;
