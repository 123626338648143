import { BrowserView, MobileView, isMobile } from "react-device-detect";

import ChromeMobile from "../../../assets/chrome_mobile.svg";
import ChromeWeb from "../../../assets/chrome_web.svg";

import PermissionMobile from "../../../assets/permission_mobile.svg";
import PermissionWeb from "../../../assets/permission_web.png";

import FallbackWeb from "../../../assets/fallback_web.png";

import FailMobile from "../../../assets/fail_mobile.svg";
import FailWeb from "../../../assets/fail_web.svg";

import SearchBar from "../../../assets/searchbar.png";
import Blocked from "../../../assets/blocked.png";
import AllowAccess from "../../../assets/allow_access.png";
import Config from "../../../assets/config.svg";

import Option from "../components/Option";

export const images = [
  ...(isMobile
    ? [
        FailMobile,
        ChromeMobile,
        PermissionMobile,
        SearchBar,
        Blocked,
        AllowAccess,
        Config,
        Option,
      ]
    : [ChromeWeb, PermissionWeb, FallbackWeb, FailWeb]),
];

const fallbacks = [
  {
    step: 1,
    text: "Botão de Opções (canto superior direito)",
    image: SearchBar,
  },
  {
    step: 2,
    text: `Configurações <span>></span> Configurações do\n Site <span>></span> Microfone ou Câmera`,
    image: null,
  },
  {
    step: 3,
    text: `Lista de bloqueados <span>></span> Nosso site`,
    image: Blocked,
  },
  {
    step: 4,
    text: `Permita o acesso`,
    image: AllowAccess,
  },
];

const fallbacks_two = [
  {
    step: 1,
    text: `No dispositivo Android/iOS, abra o\n aplicativo de <span>Configurações.</span> <img src="${Config}" alt="configurações" />`,
    image: null,
  },
  {
    step: 2,
    text: `Toque em <span>Privacidade</span> e em seguida\n <span>Gerenciar Permissões.</span>`,
    image: null,
  },
  {
    step: 3,
    text: `Toque em <span>Câmera</span> e selecione o\n aplicativo do <span>Google Chrome</span> na lista.`,
    image: null,
  },
  {
    step: 4,
    text: `Permita o acesso`,
    image: null,
  },
  {
    step: 5,
    text: `Agora repita o mesmo passo para\n permitir o <span>Microfone</span>. Assim que\n finalizar, volte para o navegador e toque\n em <span>Verificar Novamente.</span>`,
    image: null,
  },
];

const orientations = {
  chrome: {
    header: (
      <>
        <BrowserView>
          <img src={ChromeWeb} alt="google chrome" width="350" />
        </BrowserView>
        <MobileView>
          <img src={ChromeMobile} alt="google chrome" width="300" />
        </MobileView>
      </>
    ),
    title: isMobile
      ? `<span>Oi! Eu sou a Munai,</span> e gostaria de\n garantir a você a melhor experiência\n de teleconsulta possível.`
      : `<span>Oi! Eu sou a Munai,</span> e gostaria de garantir a você a\n melhor experiência de teleconsulta possível.`,
    legend: `Para começar, recomendamos fortemente o uso do\n navegador Google Chrome. Ele garante estabilidade\n na chamada de vídeo.`,
  },
  permission: {
    header: (
      <>
        <BrowserView>
          <img src={PermissionWeb} alt="solicitar permissões" className="img" height="267" />
        </BrowserView>
        <MobileView>
          <img src={PermissionMobile} alt="solicitar permissões" width="250" />
        </MobileView>
      </>
    ),
    title: isMobile
      ? `<span>Para o médico te ver e escutar,</span>\n precisamos de permissão para acessar\n sua câmera e microfone.`
      : `<span>Para o médico te ver e escutar,</span> precisamos de\n permissão para acessar sua câmera e microfone.`,
    legend: `Clicando no botão abaixo, o seu navegador irá pedir\n permissão para acessá-los. Mas não se preocupe,\n você pode desligá-los a qualquer momento.`,
  },
  fallback: {
    header: (
      <>
        <BrowserView>
          <img
            src={FallbackWeb}
            alt="falha ao solicitar permisões"
            className="img"
            height="267"
          />
        </BrowserView>
        <MobileView>
          {fallbacks.map((fallback) => (
            <Option key={fallback.step} {...fallback} />
          ))}
        </MobileView>
      </>
    ),
    title: isMobile
      ? `<span>Vamos tentar de outra maneira?</span> Siga\n as instruções acima para tentar\n autorizar novamente.`
      : `<span>Ops,</span> parece que seu navegador não está\n autorizando o acesso de sua câmera e microfone.`,
    legend: isMobile
      ? null
      : `Clicando no <b>botão de câmera</b> no canto superior direito,\n dentro da barra de buscas, você conseguirá permitir que\n nossa plataforma o acesse.`,
  },
  fallback_two: {
    header: (
      <>
        <MobileView>
          {fallbacks_two.map((fallback) => (
            <Option key={fallback.step} {...fallback} />
          ))}
        </MobileView>
      </>
    ),
    title: `<span>Acho que o seu dispositivo está negando.</span>\n Siga as instruções acima para permitir nas\n configurações internas do celular.`,
    legend: null,
  },
  fail: {
    header: (
      <>
        <BrowserView>
          <img
            src={FailWeb}
            alt="não será possível acessar a teleconsulta"
            width="275"
          />
        </BrowserView>
        <MobileView>
          <img
            src={FailMobile}
            alt="não será possível acessar a teleconsulta"
            width="175"
          />
        </MobileView>
      </>
    ),
    title: isMobile
      ? `<span>Infelizmente não podemos realizar\n a teleconsulta por este dispositivo.</span>\n Mas não se preocupe, iremos entrar\n em contato para remarcar ela.`
      : `<span>Infelizmente não podemos realizar a teleconsulta\n por este dispositivo.</span> Mas não se preocupe, iremos\n entrar em contato para remarcar ela.`,
    legend: isMobile
      ? `Na próxima vez, tente acessá-la utilizando outro\n aparelho. Caso contrário, não será possível novamente.\n Se você tem urgência no atendimento, não\n hesite em nos enviar uma mensagem no WhatsApp,\n estamos sempre à disposição.`
      : `Na próxima vez, tente acessá-la utilizando outro aparelho.\n Caso contrário, não será possível novamente.\n Se você tem urgência no atendimento, não hesite em nos enviar\n uma mensagem no WhatsApp, estamos sempre à disposição.`,
  },
};

export default orientations;
