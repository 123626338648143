import React from "react";
import { isMobile } from "react-device-detect";

import useCache from "../../hooks/useCache";
import { hasPermission } from "../../services/userMedia";
import { STEP } from "../../utils/types";
import useBeforeunload from "../../utils/useBeforeunload";

import Button from "../Button";

import { Card, Steps } from "./components";
import orientations, { images as imagesOrientations } from "./data";

import * as Styled from "./styled";

const Orientations = () => {
  const [checking, handleChecking] = React.useState(false);
  const [step, handleStep] = useCache("step", STEP.CHROME);
  const [orientation, handleOrientation] = useCache("orientation", {
    chrome: true,
    permission: false,
    fallback: false,
    fallback_two: false,
  });
  const [, handleVerified] = useCache("verified_at", null);

  React.useEffect(() => {
    imagesOrientations.forEach((image) => {
      new Image().src = image;
    });
  }, []);

  useBeforeunload(() => {
    if (step === STEP.FAIL) {
      localStorage.removeItem("step");
      localStorage.removeItem("orientation");
    }
  });

  const secondStep = React.useMemo(() => {
    if (orientation.fallback) {
      return STEP.FALLBACK_TWO;
    }

    if (orientation.permission) {
      return STEP.FALLBACK;
    }

    return STEP.PERMISSION;
  }, [orientation]);

  const steps = React.useMemo(() => {
    return [
      {
        title: "Passo 1",
        value: STEP.CHROME,
        align: "end",
      },
      {
        title: "Passo 2",
        value: secondStep,
      },
    ];
  }, [secondStep]);

  const activeOrientation = React.useMemo(
    () => (orientations[step] ? orientations[step] : {}),
    [step]
  );

  const labelButton = React.useMemo(() => {
    switch (step) {
      case STEP.PERMISSION:
        return "PEDIR PERMISSÃO";
      case STEP.FALLBACK:
      case STEP.FALLBACK_TWO:
        return "VERIFICAR NOVAMENTE";
      default:
        return "CONTINUAR";
    }
  }, [step]);

  const handleClickButton = async () => {
    switch (step) {
      case STEP.CHROME:
        handleStep(orientation.permission ? STEP.FALLBACK : STEP.PERMISSION);
        break;
      case STEP.PERMISSION:
      case STEP.FALLBACK:
      case STEP.FALLBACK_TWO:
        handleChecking(true);
        const allowed = await hasPermission({
          video: true,
          audio: true,
        });

        if (allowed) {
          handleVerified(new Date());
        } else {
          handleStep(step === STEP.PERMISSION ? STEP.FALLBACK : step);
        }

        if (step === STEP.PERMISSION) {
          handleOrientation({ ...orientation, permission: true });
        }

        handleChecking(false);
        if (allowed) {
          window.location.reload();
        }
        break;
      default:
        break;
    }
  };

  const failHandleClick = () => {
    switch (step) {
      case STEP.FALLBACK:
        handleOrientation({ ...orientation, fallback: true });

        if (isMobile) {
          handleStep(STEP.FALLBACK_TWO);
        } else {
          handleStep(STEP.FAIL);
        }

        break;
      case STEP.FALLBACK_TWO:
        handleOrientation({ ...orientation, fallback_two: true });
        handleStep(STEP.FAIL);

        break;
      default:
        break;
    }
  };

  if (step !== STEP.CHECKING) {
  }
  return (
    <Styled.Wrapper fail={step === STEP.FAIL}>
      {[STEP.CHROME, STEP.PERMISSION, STEP.FALLBACK].includes(step) && (
        <Steps activeStep={step} items={steps} onClick={handleStep} />
      )}
      <Card {...activeOrientation} step={step} />
      {![STEP.FAIL].includes(step) && (
        <Button
          onClick={handleClickButton}
          fullWidth
          maxWidth={226}
          background={
            isMobile && [STEP.FALLBACK, STEP.FALLBACK_TWO].includes(step)
              ? "#A9A9A9"
              : "#FF881F"
          }
          size="lg"
          paddingVertical={14}
          loading={checking}
          variant={isMobile ? "contained" : "outlined"}
        >
          {labelButton}
        </Button>
      )}
      <Styled.Row />
      {![STEP.CHROME, STEP.PERMISSION, STEP.FAIL].includes(step) && (
        <Button
          onClick={failHandleClick}
          fullWidth
          maxWidth={226}
          background="#707070"
          size="md"
          paddingVertical={14}
          variant="link"
        >
          NÃO ESTÁ FUNCIONANDO
        </Button>
      )}
    </Styled.Wrapper>
  );
};

export default Orientations;
