import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useLazyQuery, useSubscription } from "@apollo/client";
import useSocket from "use-socket.io-client";

import TELECONSULTATION_ROOM from "../graphql/teleconsultationRoom";
import LINK_PACIENT from "../graphql/linkPatient";
import {
  TELECONSULTATION_QUEUE,
  TELECONSULTATION_QUEUE_SUBSCTRIPTION,
} from "../graphql/teleconsultationQueue";

import Spinner from "./Loader";
import enviroment from "../utils/helpers/enviroment";
import useBeforeunload from "../utils/useBeforeunload";
import amplitudeHelper from "../utils/helpers/amplitude";

const WaitingRoom = (props) => {
  const { entityId, module, token } = useParams();
  const [pacientsQueue, setPacientsQueue] = useState(0);
  const [typeQueue, setTypeQueue] = useState(null);
  const [typeDefault, setTypeDefault] = useState(null);
  const [show, setShow] = useState(true);

  const [link, setLink] = useState(null);

  const [getLinkPacient, { data: linkPacient }] = useLazyQuery(LINK_PACIENT, {
    fetchPolicy: "network-only",
  });

  const [teleconsultationQueue, { data: queue }] = useLazyQuery(
    TELECONSULTATION_QUEUE,
    {
      fetchPolicy: "network-only",
    }
  );

  const { data: teleconsultationRoom } = useSubscription(
    TELECONSULTATION_ROOM,
    {
      variables: { entityId: props.entityId },
      shouldResubscribe: true,
      fetchPolicy: "network-first",
    }
  );

  const { data: pacientsQueueSubscription } = useSubscription(
    TELECONSULTATION_QUEUE_SUBSCTRIPTION,
    {
      variables: { entityId: props.entityId },
      shouldResubscribe: true,
      fetchPolicy: "network-first",
    }
  );

  useEffect(() => {
    teleconsultationQueue({
      variables: { entityId: parseInt(entityId), token, module },
    });
  }, [module, token, entityId, teleconsultationQueue]);

  useEffect(() => {
    if (queue) {
      setPacientsQueue(queue?.getPacientsQueue.count);
      setTypeQueue(queue?.getPacientsQueue?.type);
      setTypeDefault(queue?.getPacientsQueue?.queue);
    }
  }, [queue]);

  useEffect(() => {
    if (pacientsQueueSubscription?.getPacientsQueueSubs?.queue === true) {
      teleconsultationQueue({
        variables: { entityId: parseInt(entityId), token, module },
      });
    }
  }, [
    pacientsQueueSubscription,
    teleconsultationQueue,
    entityId,
    token,
    module,
  ]);

  useBeforeunload(() => {
    amplitudeHelper.trackEvent("user_left_the_page");
  });

  const [socket] = useSocket(enviroment.WEBSOCKET_ENDPOINT, {
    autoConnect: false,
    query: {
      application: "room-system-socket",
      entityId: parseInt(props.entityId),
      module: props.module,
      token: props.token,
    },
  });

  useEffect(() => {
    getLinkPacient({
      variables: {
        entityId: parseInt(props?.entityId),
        module: props?.module,
        token: props?.token,
      },
    });
  }, [getLinkPacient, props]);

  useEffect(() => {
    if (linkPacient?.getLinkPacient?.meetRoomPatient) {
      setLink(linkPacient?.getLinkPacient?.meetRoomPatient);
    }
  }, [linkPacient]);

  useEffect(() => {
    socket.connect();
  }, [socket]);

  useEffect(() => {
    if (teleconsultationRoom?.teleconsultationRoom?.ticket === props.token) {
      amplitudeHelper.trackEvent("user_redirected_to_room");
      window.open(
        teleconsultationRoom.teleconsultationRoom.meetRoomPatient,
        "_self"
      );
    }
  }, [teleconsultationRoom, props.token]);

  useEffect(() => {
    amplitudeHelper.setUserId(props.token);
    amplitudeHelper.trackEvent("user_the_waiting_room");
  }, [props.token]);

  useEffect(() => {
    if (link !== null) {
      window.open(link, "_self");
    }
  }, [link]);

  useEffect(() => {
    const renderText = () => {
      if (show === false) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
    setInterval(setTimeout(renderText, 8000), 8000);
  }, [show]);

  const renderTypeSchedule = () => {
    return (
      <>
        <pre className="mainWarning">
          Olá, você está na sala de espera para a Teleconsulta.
        </pre>
        <pre className="secondWarning">
          Em breve você será atendido. {"\n"}Aguarde aqui.
        </pre>
      </>
    );
  };

  const renderTypeQueue = (show, pacientsQueue) => {
    return (
      <>
        {show ? (
          <pre className="mainWarningNoWhiteSpace">
            Olá, você está na fila de espera para a teleconsulta. <br></br>
            Aguarde aqui, em breve você será atendido.
          </pre>
        ) : (
          <pre className="mainWarningNoWhiteSpace">
            Por favor, espere a teleconsulta com a tela do <br></br>
            computador ligada e com o navegador aberto.
          </pre>
        )}

        <pre className="secondWarning">Pacientes na fila</pre>
        <pre className="secondWarningNumber">{pacientsQueue}</pre>
      </>
    );
  };

  const defaultMessage = () => {
    return (
      <>
        <pre className="mainWarning">
          Olá, você está na fila de espera para a Teleconsulta.
        </pre>
        <pre className="secondWarning">
          Em breve você será atendido. {"\n"}Aguarde aqui.
        </pre>
      </>
    );
  };

  return (
    <div className="contentContainer">
      <Spinner />
      <div className="warningContainer">
        {typeQueue !== null && typeQueue === true && renderTypeSchedule()}
        {!typeQueue !== null && typeQueue === false ? (
          typeDefault !== null && typeDefault ? (
            renderTypeQueue(show, pacientsQueue)
          ) : (
            defaultMessage()
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default WaitingRoom;
