import gql from "graphql-tag";

export const TELECONSULTATION_QUEUE_SUBSCTRIPTION = gql`
  subscription getPacientsQueueSubs($entityId: Int!) {
    getPacientsQueueSubs(entityId: $entityId) {
      token
      count
      type
      queue
    }
  }
`;

export const TELECONSULTATION_QUEUE = gql`
  query getPacientsQueue($entityId: Int!, $module: String!, $token: String!) {
    getPacientsQueue(entityId: $entityId, module: $module, token: $token) {
      token
      count
      type
      queue
    }
  }
`;
