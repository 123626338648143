import gql from "graphql-tag";

const LINK_PACIENT = gql`
  query getLinkPacient($entityId: Int!, $module: String!, $token: String!) {
    getLinkPacient(entityId: $entityId, module: $module, token: $token) {
      token
      waiting
      meetRoomDateStarted
      meetRoomPatient
    }
  }
`;

export default LINK_PACIENT;
