export default class LocalStorageService {
  static setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  
  static deleteUser() {
    localStorage.removeItem('user');
  }

  static getUser() {
    const user = localStorage.getItem('user');
    if (user) return JSON.parse(user);

    return false;
  }
}
