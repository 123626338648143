import amplitude from 'amplitude-js';

import enviroment from './enviroment';

if (enviroment.AMPLITUDE_API_KEY) {
  amplitude.getInstance().init(enviroment.AMPLITUDE_API_KEY);
}

const amplitudeHelper = {
  setUserId(userId) {
    if (enviroment.AMPLITUDE_API_KEY) {
      amplitude.getInstance().setUserId(userId);
    }
  },
  trackEvent(eventName, eventProperties) {
    if (enviroment.AMPLITUDE_API_KEY) {
      amplitude.getInstance().logEvent(eventName, eventProperties);
    }
  }
}

export default amplitudeHelper;