import React from "react";
import { isMobile } from "react-device-detect";

import LogoWhite from "../../../../assets/logo-white.svg";

import * as Styled from "./styled";

const Logo = () => (
  <Styled.LogoWrapper>
    <img src={LogoWhite} alt="Munai" height={isMobile ? 35 : 45} />
  </Styled.LogoWrapper>
);

export default Logo;
