import { isMobile } from "react-device-detect";
import styled from "styled-components";

export const Wrapper = styled.div.attrs(props => ({
  padding: props.fail ? isMobile ? 20 : 85 : isMobile ? 20 : 60,
}))`
  background-color: #f4f5f7;
  display: flex;
  padding-top: ${(props) => props.padding}px;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
`;

export const Row = styled.div`
  height: 10px;
`;
