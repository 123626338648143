import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { hotjar } from "react-hotjar";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import Reception from "./components/Reception";
import ApolloService from "./services/ApolloService";
import * as serviceWorker from "./serviceWorker";
import enviroment from "./utils/helpers/enviroment";
import Orientations from "./components/Orientations";
import useCache from "./hooks/useCache";
import GlobalStyle from "./styles/global";

if (enviroment.isProduction) {
  hotjar.initialize(enviroment.HOTJAR_ID, enviroment.HOTJAR_SV);
}

if (enviroment.SENTRY_DSN) {
  Sentry.init({
    dsn: enviroment.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: parseInt(enviroment.SENTRY_TRACES_SAMPLE_RATE || "0.2"),
    release: enviroment.VERSION,
    environment: enviroment.SENTRY_ENVIRONMENT,
  });
}

const App = Sentry.withProfiler(() => {
  const [verified] = useCache("verified_at", null);

  return (
    <ApolloProvider client={ApolloService}>
      <GlobalStyle verified={verified} />
      <BrowserRouter>
        <Switch>
          {!verified ? (
            <Route component={Orientations} path={"/"} />
          ) : (
            <>
              <Route component={Reception} exact path={"/"} />
              <Route component={Reception} exact path={"/:entityId"} />
              <Route component={Reception} exact path={"/:entityId/:token"} />
              <Route
                component={Reception}
                exact
                path={"/:entityId/:module/:token"}
              />
              <Route exact path={"/status"}>
                <>OK</>
              </Route>
            </>
          )}
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
