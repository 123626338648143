import styled from "styled-components";
import { isMobile } from "react-device-detect";

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff881f;
  width: ${isMobile ? 49 : 62}px;
  height: ${isMobile ? 49 : 62}px;
  z-index: 999;
  box-shadow: 0px 0px 15px #0000000f;
  border-radius: 8px;
  margin: ${isMobile ? -24.5 : -31}px auto;
  position: relative;
`;
