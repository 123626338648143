export const hasPermission = async (requestedMedia) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(
      requestedMedia
    );

    stream.getTracks().forEach(track => track.stop());

    return true;
  } catch (err) {
    return false;
  }
}