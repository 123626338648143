import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    width: 100%;
    background-color: ${(props) => (props.verified ? "#ff881f" : "#f4f5f7")};
  }

  body,
  html {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  #root {
    display: flex;
    justify-content: center;
  }

  p,
  pre {
    font-family: "Roboto", sans-serif;
  }

  .container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 41px 45px;
    max-width: 523px;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .warningContainer {
    margin-top: 41px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .mainWarning {
    font-size: 28px;
    font-weight: 300;
    color: #fff;
    white-space: pre-wrap;
    text-align: center;
  }

  .mainWarningNoWhiteSpace {
    font-size: 28px;
    font-weight: 300;
    color: #fff;
    text-align: center;
    @media (max-width: 768px) {
      inline-size: 95vw;
    overflow-wrap: break-word;
      white-space: pre-wrap;
    }
  }

  .secondWarning {
    font-size: 18px;
    color: #fff;
    font-weight: 300;
    margin-top: 35px;
    margin-bottom: 2px;
    white-space: pre-wrap;
    text-align: center;
  }

  .secondWarningNumber {
    font-size: 28px;
    color: #fff;
    font-weight: bold;
    margin-top: -2px;
    white-space: pre-wrap;
    text-align: center;
  }
`;

export default GlobalStyle;
