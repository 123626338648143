import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';

export const Wrapper = styled.div.attrs(props => ({
  height: props.height,
  align: props.align || 'center',
}))`
  background-color: #A9A9A9;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: ${props => isMobile ? props.height ? props.height : 327 : 267}px;
  display: flex;
  align-items: ${props => props.align};
  justify-content: center;

  ${({align}) => align === 'flex-start' && css `
    padding-top: 27px;
  `}

  .img {
    margin-top: 5px;
  }
`;