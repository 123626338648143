import React from "react";
import PropTypes from "prop-types";

import * as Styled from "./styled";

const Header = ({ children, height, align }) => {
  return (
    <Styled.Wrapper height={height} align={align}>
      {children}
    </Styled.Wrapper>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
  align: PropTypes.string,
};

export default Header;
