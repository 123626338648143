import styled, { keyframes, css } from 'styled-components';
import { cssValue } from '../../utils/helpers/unitConverter';

export const sizes = {
  xs: { size: 6, margin: 0.5 },
  sm: { size: 8, margin: 0.5 },
  md: { size: 10, margin: 0.5 },
  lg: { size: 12, margin: 0.5 },
  xl: { size: 14, margin: 0.5 },
};

const beat = keyframes`
  50% {
    transform: scale(0.75);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const sharedProps = (props) => ({
  size: props.size,
  color: props.color,
  margin: props.margin,
  speedMultiplier: props.speedMultiplier,
  index: props.index,
});

export const Container = styled.div``;

export const Spinner = styled.div.attrs(sharedProps)`
  border-radius: 100%;
  display: inline-block;
  animation-fill-mode: both;

  ${({ size, color, margin, speedMultiplier, index }) => css`
    width: ${cssValue(size)};
    height: ${cssValue(size)};
    background-color: ${color};
    margin: ${cssValue(margin)};
    animation: ${beat} ${0.7 / speedMultiplier}s ${index % 2 ? '0s' : `${0.35 / speedMultiplier}s`}
      infinite linear; ;
  `}
`;
