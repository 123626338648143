import hostnameVars, { isProduction } from '../hostnameVars';

const enviroment =  {
  isProduction: isProduction(),
  VERSION: process.env.REACT_APP_VERSION,
  GRAPHQL_ENDPOINT: hostnameVars('REACT_APP_GRAPHQL_ENDPOINT'),
  GRAPHQL_WEBSOCKET_ENDPOINT: hostnameVars('REACT_APP_GRAPHQL_WEBSOCKET_ENDPOINT'),
  WEBSOCKET_ENDPOINT: hostnameVars('REACT_APP_WEBSOCKET_ENDPOINT'),
  AMPLITUDE_API_KEY: hostnameVars('REACT_APP_AMPLITUDE'),
  HOTJAR_ID: hostnameVars('REACT_APP_HOTJAR_ID'),
  HOTJAR_SV: hostnameVars('REACT_APP_HOTJAR_SV'),
  SENTRY_DSN: hostnameVars('REACT_APP_SENTRY_DSN'),
  SENTRY_TRACES_SAMPLE_RATE: hostnameVars('REACT_APP_SENTRY_TRACES_SAMPLE_RATE'),
  SENTRY_ENVIRONMENT: hostnameVars('REACT_APP_SENTRY_ENVIRONMENT'),
};

export default enviroment;
