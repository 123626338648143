import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { lighten } from 'polished';

export const sizesRem = {
  sm: 0.55,
  md: 0.65,
  lg: 0.85,
};

export const sizesPx = {
  sm: 12,
  md: 14,
  lg: 16,
};

export const Button = styled(motion.button).attrs((props) => ({
  size: props.size,
  hasLabel: props.hasLabel,
  color: props.color,
  background: props.background,
  fullWidth: props.fullWidth,
  maxWidth: props.maxWidth,
  rounded: props.rounded,
  disabled: props.disabled,
  variant: props.variant,
  loading: props.loading,
  paddingVertical: props.paddingVertical,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: 'Roboto';
  font-weight: 500;

  ${({
    size,
    hasLabel,
    color,
    background,
    fullWidth,
    rounded,
    maxWidth,
    disabled,
    variant,
    loading,
    paddingVertical,
  }) => css`
    font-size: ${sizesPx[size]}px;
    border-radius: ${rounded ? 50 : 5}px;
    padding: ${loading || hasLabel ? paddingVertical : 11}px ${hasLabel || loading ? 16 : 8}px;
    color: ${disabled ? '#707070' : color};
    background: ${disabled ? '#E3E3E3' : background};
    border: 1px solid ${disabled ? '#E3E3E3' : background};
    max-width: ${maxWidth ? `${maxWidth}px` : '100%'};
    width: ${fullWidth ? '100%' : 'auto'};
    cursor: ${disabled ? 'default' : 'pointer'};

    ${variant === 'contained' &&
    !disabled &&
    css`
      &:hover {
        background: ${lighten(0.2, background)};
        border: 1px solid ${lighten(0.2, background)};
      }
    `}

    ${variant === 'outlined' &&
    css`
      border: 1px solid ${disabled ? '#707070' : color};
      background: transparent;
    `}

    ${variant === 'link' &&
    css`
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: transparent;
      text-decoration: underline;
    `}
  `}

  &:focus {
    outline: 0;
  }
`;

export const Spacing = styled.div`
  width: 10px;
`;
