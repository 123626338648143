import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import VALIDATE_ROOM_TOKEN from "../graphql/validateRoomToken";
import LocalStorageService from "../services/LocalStorageService";

import Logo from "../assets/logo-white.svg";

import WaitingRoom from "./WaitingRoom";
import Error from "./Error";
import Spinner from "./Loader";

const Reception = (props) => {
  const { entityId, module, token } = useParams();
  const [validateRoomToken, { loading, data: tokenData, error }] = useLazyQuery(
    VALIDATE_ROOM_TOKEN,
    {
      fetchPolicy: "network-only",
    }
  );
  const [awaitingTimeout, handleAwaitingTimeout] = React.useState(false);

  const isRefreshing = React.useMemo(
    () => window.performance?.navigation?.type === 1,
    []
  );

  useEffect(() => {
    if (isRefreshing) {
      handleAwaitingTimeout(true);
    }

    setTimeout(
      () => {
        if (module && token) {
          validateRoomToken({
            variables: { entityId: parseInt(entityId), token, module },
          });
          handleAwaitingTimeout(false);
        }
      },
      isRefreshing ? 3000 : 0
    );
  }, [module, token, entityId, validateRoomToken, isRefreshing, handleAwaitingTimeout]);

  useEffect(() => {
    if (tokenData?.validateRoomToken?.jwtToken) {
      LocalStorageService.setUser({
        jwtToken: tokenData.validateRoomToken.jwtToken,
      });
    }
    // eslint-disable-next-line
  }, [tokenData, token]);

  return (
    <div className="container">
      <img src={Logo} alt="Munai" height={56} />
      <div className="contentContainer">
        {entityId && token ? (
          loading || awaitingTimeout ? (
            <Spinner />
          ) : (
            <>
              {error || tokenData === undefined ? (
                <Error
                  error={
                    error?.message
                      ? error.message.replace("Error:", "").trim()
                      : "Ocorreu um erro inesperado"
                  }
                />
              ) : (
                <WaitingRoom
                  {...props}
                  token={token}
                  entityId={tokenData?.validateRoomToken.entityId}
                  module={module}
                />
              )}
            </>
          )
        ) : (
          <h1 className="mainWarning">
            Bem vindo ao {"\n"} sistema de sala da Munai.
          </h1>
        )}
      </div>
      <div />
    </div>
  );
};

export default Reception;
