import React from "react";
import PropTypes from "prop-types";

import * as Styled from "./styled";

const Steps = ({ items, onClick, activeStep }) => {
  return (
    <Styled.Wrapper>
      {items.map((step, index) => (
        <Styled.Step
          key={index}
          onClick={() => onClick(step.value)}
          active={step.value === activeStep}
          align={step.align}
        >
          <Styled.StepCircle active={step.value === activeStep} />
          {step.title}
        </Styled.Step>
      ))}
    </Styled.Wrapper>
  );
};

Steps.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string,
      align: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func,
  activeStep: PropTypes.string,
};

Steps.defaultProps = {
  onClick: () => {},
  items: [],
  activeStep: null,
};

export default Steps;
