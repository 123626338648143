import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: flex-start;
  padding: 0px 20px;
  box-sizing: border-box;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  min-width: 26px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
`;

export const StepText = styled.div`
  margin-left: 20px;
  color: #ffffff;
  font-size: 14px;
  white-space: pre-line;
  margin-top: 3px;
  line-height: 18px;

  > span {
    color: #444444;
  }

  > img {
    position: absolute;
    margin-left: 3px;
  }
`;

export const StepImage = styled.img`
  margin-top: 8px;
  position: relative !important;
  margin-left: 0px !important;
`;

