const hostname = window.location.hostname;

export default function hostnameVars(environmentVar) {
    if (hostname === process.env.REACT_APP_HOSTNAME_LOCAL && process.env[`${environmentVar}_LOCAL`]) {
        return process.env[`${environmentVar}_LOCAL`];
    }

    if (hostname === process.env.REACT_APP_HOSTNAME_DEVELOP && process.env[`${environmentVar}_DEVELOP`]) {
        return process.env[`${environmentVar}_DEVELOP`];
    }

    if (hostname === process.env.REACT_APP_HOSTNAME_STAGING && process.env[`${environmentVar}_STAGING`]) {
        return process.env[`${environmentVar}_STAGING`];
    }

    return process.env[environmentVar]
}

export function isProduction() {
    if (hostname === process.env.REACT_APP_HOSTNAME_LOCAL)
        return false;

    if (hostname === process.env.REACT_APP_HOSTNAME_DEVELOP)
        return false;

    if (hostname === process.env.REACT_APP_HOSTNAME_STAGING)
        return false;

    return true;
}