import gql from "graphql-tag";

const TELECONSULTATION_ROOM = gql`
  subscription teleconsultationRoom($entityId: Int!) {
    teleconsultationRoom(entityId: $entityId) {
      ticket
      meetRoomPatient
    }
  }
`;
export default TELECONSULTATION_ROOM;
