import React from 'react';
import invariant from 'tiny-invariant';

const useBeforeunload = (handler) => {
  invariant(
    handler == null || typeof handler === 'function',
    'Expected `handler` to be a function'
  );

  const eventListenerRef = React.useRef();

  const eventListener = React.useCallback((event) => {
    eventListenerRef?.current(event);
  }, [eventListenerRef]);

  const removeEventListener = React.useCallback(() => {
    window.removeEventListener('beforeunload', eventListener);
  }, [eventListener]);

  React.useEffect(() => {
    eventListenerRef.current = (event) => {
      const returnValue = handler?.(event);
      // Handle legacy `event.returnValue` property
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      if (typeof returnValue === 'string') {
        return (event.returnValue = returnValue);
      }
      // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
      // instead it requires `event.returnValue` to be set
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
      if (event.defaultPrevented) {
        return (event.returnValue = '');
      }
    };
  }, [handler]);

  React.useEffect(() => {
    window.addEventListener('beforeunload', eventListener);
    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  }, [eventListener]);

  return removeEventListener;
};

export default useBeforeunload;